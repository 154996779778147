import React, { useState, useEffect } from 'react';
import { Container, PageTransitionWrapper, PageTransitionLogo } from './PageTransition.styled';
import { setDone } from '@/redux/pageTransition/pageTransitionSlice';
import { connect, ConnectedProps } from 'react-redux';
import { useRouter } from 'next/router';
import Logo from '@/icons/logo-skeleton.svg';
import LogoEn from '@/icons/logo-en-skeleton.svg';
// import Skeletons from '../Skeletons/Skeletons';

const mapDispatchToProps = { setDone };
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PageTransitionType = PropsFromRedux & {
    className?: string;
};

export const PAGES_WITHOUT_HEAD = [
    '/clients/accounting-docs',
    '/clients/organizations',
    '/education/',
    '/partners',
    '/contacts',
    '/documents',
    '/qualification',
    '/rosneft/edinoe_okno',
    '/rosneft/importozameshchenie',
    '/suppliers-foreign',
    '/anketa-podradcika-smr',
    '/rosneft/news',
    '/help',
    '/service',
    '/pz',
    '/ekosreda',
    '/news',
    'procedures',
    '/tariff',
    '/uvs-ip'
];

const PageTransition: React.FC<PageTransitionType> = ({ className, setDone }) => {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    // const [variant, setVariant] = useState<'main' | 'nohead' | 'default'>('default');

    useEffect(() => {
        const handleStart = (url: string, { shallow }: any) => {
            if (url !== router.asPath && !shallow) {
                // if (url === '/') {
                //     setVariant('main');
                // } else if (PAGES_WITHOUT_HEAD.filter((item) => url.includes(item)).length > 0) {
                //     setVariant('nohead');
                // } else {
                //     setVariant('default');
                // }
                setLoading(true);
            }
        };
        const handleComplete = () => {
            setLoading(false);
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    }, []);

    useEffect(() => {
        loading ? setDone(false) : setDone(true);
    }, [loading, setDone]);

    return (
        <Container className={className} showed={loading}>
            {/*<Skeletons variant={variant} />*/}
            <PageTransitionWrapper>
                <PageTransitionLogo>{router.locale === 'ru' ? <Logo /> : <LogoEn />}</PageTransitionLogo>
                {/*<p>{router.locale === 'ru' ? 'Делаем закупки доступнее' : 'We make shopping easier'}</p>*/}
            </PageTransitionWrapper>
        </Container>
    );
};

export default connector(PageTransition);
