import styled, { keyframes } from 'styled-components';
import { adaptiveHardValues, color, mediaBreakpointUp, offset } from '@/style/mixins';
import variables from '@/style/variables';

const pulse = keyframes`
    0% {
        transform: scale(0.9);   
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9); 
    }
`;

export const Container = styled.div<{ showed?: boolean; leave?: boolean }>`
    position: fixed;
    background-color: ${color('Bg-Light')};
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;

    visibility: ${({ showed }) => (showed ? 'visible' : 'hidden')};
    opacity: ${({ showed }) => (showed ? '1' : '0')};

    transition: visibility ${({ showed }) => (showed ? '0.5s cubic-bezier(.165,.84,.44,1)' : '0.5s 0.1s cubic-bezier(.165,.84,.44,1)')},
        opacity ${({ showed }) => (showed ? '0.5s cubic-bezier(.165,.84,.44,1)' : '0.5s 0.1s cubic-bezier(.165,.84,.44,1)')};
`;

export const PageTransitionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: ${pulse} infinite 2s;

    ${offset('l', 'gap')};

    p {
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 800;
        letter-spacing: -0.02em;
        ${adaptiveHardValues('font-size', 30, 20, 18)};
        color: ${color('link-default')};
    }
`;

export const PageTransitionLogo = styled.div`
    width: 178px;
    height: 40px;

    ${mediaBreakpointUp('md')} {
        width: 290px;
        height: 65px;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
